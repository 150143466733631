import parser from "html-react-parser";
import { removeWidows } from "string-remove-widows";

export const widont = (string = null, parse = false) => {
  if (!string) return;

  if (parse) return parser(removeWidows(string).res);

  return removeWidows(string).res;
};
