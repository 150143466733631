const getImageBlurOptions = (image, imageBlur = null) => {
  let imageBlurOptions = {};

  if (!!image && image.extension !== "svg" && !!image.blur) {
    imageBlurOptions = {
      placeholder: "blur",
      blurDataURL: image.blur[0].url,
    };
  }

  return imageBlurOptions;
};

export default getImageBlurOptions;
