import clsx from "clsx";
import { useState } from "react";

import { ArrowDown, ArrowRight } from "@components/Icons";
import Target from "@components/Target";

const SecondaryButton = ({
  target,
  isFullWidth = false,
  isFullWidthMobile = false,
  textColor = "currentColor",
  legacyBackgroundColor = "transparent",
  borderColor = "currentColor",
  hoverBackgroundColor = "#0D1418",
  hoverTextColor = "#f7f7f7",
  noXPadding = false,
  transition = true,
  hasArrow = true,
  downloadAssets = true, // Note: will not work until Servd Asset v3
}) => {

  const [isHovered, setIsHovered] = useState(false);
  const [targetBackgroundColor, setTargetBackgroundColor] = useState(
    legacyBackgroundColor
  );
  const [targetTextColor, setTargetTextColor] = useState(textColor);

  const targetBackgroundHoverColor =
    hoverBackgroundColor && hoverBackgroundColor !== "transparent"
      ? hoverBackgroundColor
      : legacyBackgroundColor;

  const targetTextHoverColor =
    hoverTextColor && hoverTextColor !== "transparent"
      ? hoverTextColor
      : textColor;
  
  const targetBorderColor =
    legacyBackgroundColor === "transparent" || borderColor !== "currentColor"
      ? borderColor
      : legacyBackgroundColor;
  
  const handleClick = (text, url) => {
    window.dataLayer?.push({
      event: "event",
      eventProps: {
        category: target.type === "asset" ? "File Download" : "Button Click",
        action: text,
        label: url,
      },
    });
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    setTargetBackgroundColor(targetBackgroundHoverColor);
    setTargetTextColor(targetTextHoverColor);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setTargetBackgroundColor(legacyBackgroundColor);
    setTargetTextColor(textColor);
  };

  const isDownloadArrow = () => {
    if (target.type === "asset") {
      return true;
    } 
    return false;
  };

  const ArrowIcon = () => {
    if (isDownloadArrow()) {
      return <ArrowDown />;
    }
    return <ArrowRight />;
  };

  return (
    <Target
      target={target}
      className={clsx(
        "SecondaryButton",
        "inline-flex items-center",
        "py-2",
        "border rounded-sm",
        {
          "w-full": isFullWidth,
          "w-full lg:w-auto": isFullWidthMobile,
          "px-5": !noXPadding,
        },
        { "transition-colors": transition },
        { "hover:!border-black-01": borderColor !== "transparent" },
        "group"
      )}
      style={{
        color: targetTextColor,
        backgroundColor: targetBackgroundColor,
        borderColor: targetBorderColor,
      }}
      handleClick={() => handleClick(target.text, target.url)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      download={downloadAssets}
    >
      {hasArrow && isDownloadArrow() && (
        <span
          className={clsx(
            "SecondaryButton__icon",
            "pr-4",
            "transition-transform group-hover:translate-y-1"
          )}
        >
          <ArrowIcon />
        </span>
      )}

      <span className="SecondaryButton__label">{target.text}</span>

      {hasArrow && !isDownloadArrow() && (
        <span
          className={clsx(
            "SecondaryButton__icon",
            "pl-4 ml-auto",
            "transition-transform group-hover:translate-x-1"
          )}
        >
          <ArrowIcon />
        </span>
      )}
    </Target>
  );
};

export default SecondaryButton;
