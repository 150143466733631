import React from "react";
import { Text } from '@chakra-ui/react'
import parse from "html-react-parser";

interface QuotationProps {
  quotation: string;
  quotationStyle?: boolean;
  attribution: string;
  role: string;
}

function parseContent(content: string) {
  return parse(content);
}

function Quotation({ quotation, quotationStyle, attribution, role }: QuotationProps) {

  // Sanitize the HTML as Craft adds extra element tags that render as text
  const sanitizedQuotation = quotation ? parseContent(quotation) : null;

  return (
    quotationStyle ?
      // Emphasizes style 
      <blockquote>
        <div style={{ fontSize: "32px", fontWeight: "400", lineHeight: "55px" }}>
          {sanitizedQuotation}
        </div>
        { attribution && role ? (
          <Text fontSize="16px" paddingTop="26px">
            &mdash;{attribution}, {role}
          </Text>
        ) : (
          attribution ? (
            <Text fontSize="16px" paddingTop="26px">
              &mdash;{attribution}
            </Text>
          ) : (
            role ? (
              <Text fontSize="16px" paddingTop="26px">
                &mdash;{role}
              </Text>
            ) : null
          )
        )}
      </blockquote> 
      :
      // Non emphasized style
      <blockquote> 
        <div>
          {sanitizedQuotation}
        </div>
      </blockquote> 
  );
}

export default Quotation;
