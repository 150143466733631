import clsx from "clsx";
import parse from "html-react-parser";
import React from "react";

interface IProps {
	text: string;
	textColor?: string;
	fullWidth?: boolean;
}

const BodyText = ({
	text,
	textColor = "currentColor",
	fullWidth = false,
}: IProps): JSX.Element => {
	return (!!text && (
		<div className={clsx("BodyText")}>
			<div
				className={clsx("BodyText__content prose max-w-none")}
				style={{ color: textColor }}
			>
				{parse(text)}
			</div>
		</div>
	));
};

export default BodyText;
