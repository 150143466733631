import clsx from "clsx";
import parse from "html-react-parser";
import Image from 'next/image'
import ClipLoader from "react-spinners/ClipLoader";

import { Box } from "@chakra-ui/react";
import LogoBlock from "@components/LogoBlock";
import SecondaryButton from "@components/SecondaryButton";
import getImageBlurOptions from "@lib/getImageBlurOptions";
import { widont } from "@lib/widont";
import router from "next/router";
import React, { useRef } from "react";
import { ReactSVG } from "react-svg";
import { useMeasure } from "react-use";

const ContentBlock = ({
  icon,
  heading,
  headingColor,
  text,
  target,
  targetColor,
  legacyButtonBackgroundColor,
  buttonHoverBackgroundColor,
  buttonHoverTextColor,
  secondaryTarget,
  secondaryTargetColor,
  layout = "vertical",
  headingSize = "md",
  footnote,
  centerColumns,
  textColor = "currentColor",
  legacyBackgroundColor = "transparent",
  padding = false,
  spanColumns = false,
  logos = [],
  backgroundImage,
  backgroundImageBlur,
  textAlignment = "left",
  borderColor = "transparent",
  hoverBackgroundColor = "transparent",
  hoverTextColor = "currentColor",
  image,
  imageBlur,
  mobileImage,
  mobileImageBlur,
  mobileMediaPosition = "above",
  showButtonBorder = false,
  isHovered,
  iconColor,
}) => {
  const targetMobileImage = !!mobileImage ? mobileImage : image;
  const targetMobileImageBlur = !!mobileImage ? mobileImageBlur : imageBlur;

  const [ref, bounds] = useMeasure();
  const [mobileRef, mobileBounds] = useMeasure();

  const handleClick = (e, path, type) => {
    e.preventDefault();
    if (type === "url") {
      window.open(path);
    } else {
      router.push(path);
    }
  };

  return (
    <div
      onClick={target.url && ((e) => handleClick(e, target.url, target.type))}
      className={clsx("ContentBlock", "relative", "flex", "h-full", {
        "flex-col": layout === "vertical",
        "flex-row": layout === "horizontal",
        "md:py-12 p-5 md:p-7.5": padding && !icon,
        "md:py-5 p-5 md:p-7.5": padding && icon,
        "text-center items-center": textAlignment === "center",
        "items-center": centerColumns && layout === "horizontal",
        "justify-center": centerColumns && layout !== "horizontal",
        "cursor-pointer": target?.url,
      })}
    >
      {!!icon?.url && (
        <Box
          className={clsx(
            "ContentBlock__icon",
            "flex justify-center items-center",
            "z-10",
            "relative",
            {
              "-top-2 pr-2.5": layout === "horizontal",
              "flex-grow-0 flex-shrink-0 w-[45px] lg:w-[60px] h-[45px] lg:h-[60px] mb-7.5":
                text || (heading && !(!text && !heading)),
              "w-full h-full max-w-[120px] content-center flex items-center":
                !text && !heading,
            }
          )}
          color={
            target?.url && isHovered && layout !== "horizontal"
              ? "#fff"
              : iconColor
          }
        >
          {icon.extension === "svg" && (
            <ReactSVG
              loading={() => <ClipLoader />}
              beforeInjection={(svg) => {
                svg.querySelectorAll("*").forEach((x) => {
                  x.removeAttribute("font-family");
                  x.hasAttribute("stroke") &&
                    x.setAttribute("stroke", "currentColor");
                });
              }}
              className={`icon-${icon.id}`}
              wrapper="span"
              src={
                process.env.NODE_ENV === "development"
                  ? "/servd-volumes/images/" + icon.path
                  : icon.url
              }
            />
          )}

          {icon.extension !== "svg" && (
            <Image
              src={icon.url}
              alt={icon.title}
              layout="fill"
              objectFit="contain"
              objectPosition={text || heading ? "center left" : "center"}
            />
          )}
        </Box>
      )}

      <div
        className={clsx("ContentBlock__content z-10", {
          "flex-grow flex flex-col": layout !== "vertical",
        })}
      >
        {!!heading && (
          <h3
            className={clsx("ContentBlock__heading", {
              "text-2xl": headingSize === "sm",
              "text-2xl lg:text-3xl": headingSize === "md",
              "text-4xl lg:text-5xl": headingSize === "lg",
              "mb-4 lg:mb-7.5": text,
            })}
            style={{
              color: headingColor,
            }}
          >
            <div
              className={clsx({
                "hover-underline group-hover:hover-underline-show":
                  !!target && !!target.url && !hoverBackgroundColor,
              })}
            >
              <span className={clsx("py-1")}>{widont(heading, true)}</span>
            </div>
          </h3>
        )}
        {!!text && (
          <div
            className={clsx("ContentBlock__text", {
              "lg:mx-auto": textAlignment === "center",
              prose: !spanColumns,
              "max-w-lg": !spanColumns,
            })}
          >
            {parse(text)}
          </div>
        )}
        {!!footnote && (
          <div
            className={clsx(
              "ContentBlock__footnote",
              "max-w-sm",
              "text-xs",
              "mt-4",
              {
                "mx-auto": textAlignment === "center",
              }
            )}
          >
            {widont(footnote, true)}
          </div>
        )}
        {!!target && !!target.url && (
          <div
            className={clsx("ContentBlock__linkWrapper", {
              "lg:flex lg:justify-center": textAlignment === "center",
              "pt-7.5 mt-auto": !hoverBackgroundColor,
            })}
          >
            <div className={clsx("SideBySide__secondaryTarget", "mt-4")}>
              <SecondaryButton
                target={target}
                textColor={targetColor}
                legacyBackgroundColor={legacyButtonBackgroundColor}
                hoverBackgroundColor={
                  !!legacyButtonBackgroundColor && !!buttonHoverBackgroundColor
                    ? buttonHoverBackgroundColor
                    : !!legacyButtonBackgroundColor
                    ? "#000"
                    : "transparent"
                }
                hoverTextColor={
                  !!buttonHoverTextColor ? buttonHoverTextColor : hoverTextColor
                }
                noXPadding={!legacyButtonBackgroundColor && true}
                transition={!hoverBackgroundColor}
                borderColor={"transparent"}
              />
            </div>
          </div>
        )}

        {!!secondaryTarget && !!secondaryTarget.url && (
          <div
            className={clsx("ContentBlock__linkWrapper", "pt-3 mt-auto", {
              "lg:flex lg:justify-center": textAlignment === "center",
            })}
          >
            <SecondaryButton
              target={secondaryTarget}
              textColor={secondaryTargetColor}
              hoverBackgroundColor={"transparent"}
              borderColor={"transparent"}
              hoverTextColor={hoverTextColor}
              noXPadding={true}
            />
          </div>
        )}

        {logos.length > 0 && (
          <div
            className={clsx(
              "ContentBlock__logos",
              "mt-7.5",
              "flex flex-wrap justify-center gap-7.5"
            )}
          >
            {logos.map((logo) => {
              return (
                <LogoBlock
                  key={logo.id}
                  image={logo}
                  withMargin={false}
                  contentBlock={true}
                />
              );
            })}
          </div>
        )}
        {!!image?.url && (
          <div
            className={clsx("ContentBlock__imageWrapper", "hidden lg:block")}
            ref={ref}
          >
            <Image
              key={image.id}
              src={image.url}
              width={image.width}
              height={image.height}
              alt={image.title}
              sizes={
                bounds.width !== undefined
                  ? `${Math.round(bounds.width)}px`
                  : "100vw"
              }
              layout="responsive"
              className={clsx("ContentBlock__image")}
              {...getImageBlurOptions(image, imageBlur)}
            />
          </div>
        )}
        {!!targetMobileImage?.url && (
          <div
            className={clsx(
              "ContentBlock__mobileImageWrapper",
              "block md:w-full lg:hidden"
            )}
            ref={mobileRef}
          >
            <Image
              key={targetMobileImage.id}
              src={targetMobileImage.url}
              width={targetMobileImage.width}
              height={targetMobileImage.height}
              alt={targetMobileImage.title}
              sizes={
                mobileBounds.width !== undefined
                  ? `${Math.round(mobileBounds.width)}px`
                  : "100vw"
              }
              className={clsx("ContentBlock__mobileImage")}
              {...getImageBlurOptions(targetMobileImage, targetMobileImageBlur)}
            />
          </div>
        )}
      </div>

      {!!backgroundImage?.url && (
        <div
          className={clsx(
            "ContentBlock__backgroundImage",
            "absolute inset-0 z-0"
          )}
        >
          <Image
            src={backgroundImage.url}
            layout="fill"
            objectFit="cover"
            alt={backgroundImage.title}
            draggable={false}
            {...getImageBlurOptions(backgroundImage, backgroundImageBlur)}
          />
        </div>
      )}
    </div>
  );
};

export default ContentBlock;
