import { Box } from "@chakra-ui/react";
import clsx from "clsx";
import Image from "next/image";

const LogoBlock = ({
	image,
	withMargin = true,
	contentBlock = false,
	bottomMargin = true,
	imageHeight = 60,
}) => {
	const imageWidth = (imageHeight / image.height) * image.width;
	return (
		<Box
			h={`${imageHeight}px`}
			className={clsx(
				"LogoBlock",
				"relative",
				"flex items-center justify-center",
				{
					"mx-6 md:mx-6 lg:mx-6 xl:mx-6 2xl:mx-7.5 my-4": withMargin,
					"mb-7.5 md:mb-0": bottomMargin,
				}
			)}
			bgImage={!contentBlock && image.url}
			bgSize="contain"
			bgRepeat="no-repeat"
			bgPosition="center"
		>
			{contentBlock && (
				<Image
					alt={image.title}
					src={image.url}
					width={imageWidth}
					height={imageHeight}
					style={{ maxHeight: `${imageHeight}px`, width: "auto" }}
				/>
			)}
		</Box>
	);
};

export default LogoBlock;
