import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { PlayIcon } from "@components/Icons";
import VideoEmbed from "@components/VideoEmbed";
import IImage from "@sharedInterfaces/Image.interface";
import Image from "next/image";
import { useState } from "react";

interface IProps {
  autoplay?: boolean;
  centered?: boolean;
  className?: string;
  controls?: boolean;
  deprecatedMode?: boolean;
  iconColor?: string;
  iconColorHover?: string;
  isVideoOpen?: boolean;
  playIconWidth?: string;
  returnFocusOnClose?: boolean;
  showPlayIcon?: boolean;
  size?: string;
  videoThumbnail?: IImage;
  videoUrl: string;
  onClose?: () => void;
}

const VideoModal = ({
  autoplay,
  centered,
  className,
  controls,
  deprecatedMode = false,
  iconColor = "#6863F2",
  iconColorHover = "#8985f2",
  isVideoOpen = false,
  playIconWidth,
  returnFocusOnClose = true,
  showPlayIcon = true,
  size = "xl",
  videoThumbnail,
  videoUrl,
  onClose = () => null,
}: IProps) => {
  if (!videoUrl || !videoThumbnail) return null;

  const [isOpen, setIsOpen] = useState(isVideoOpen);

  const handleClose = () => {
    onClose();
    setIsOpen(false);
  };
  const handleOpen = () => setIsOpen(true);

  if (deprecatedMode) {
    // TODO: Phase out deprecated handling
    return (
      <Modal
        isCentered={centered}
        isOpen={isOpen}
        onClose={handleClose}
        blockScrollOnMount={false}
        returnFocusOnClose={returnFocusOnClose}
        size={size}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton zIndex="9999" />
          <ModalBody>
            <VideoEmbed url={videoUrl} autoplay videoStyle="inModal" onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Box
      className={className}
      cursor="pointer"
      onClick={handleOpen}
      position="relative"
      w="100%"
    >
      <Image
        src={videoThumbnail.url}
        width={videoThumbnail.width}
        height={videoThumbnail.height}
        alt={videoThumbnail.title}
        layout={"intrinsic"}
        placeholder={
          videoThumbnail.extension !== "svg" && videoThumbnail.blur
            ? "blur"
            : "empty"
        }
        blurDataURL={
          videoThumbnail.blur ? videoThumbnail.blur[0].url : null
        }
        className="VideoEmbed__thumbnail w-full"
      />
      <Button
        position="absolute"
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        top="0"
        left="0"
        variant="unstyled"
        color={iconColor}
        _focus={{ color: iconColorHover, outline: "none" }}
        _hover={{ color: iconColorHover }}
      >
      { showPlayIcon && <Box w={playIconWidth || "101px"}><PlayIcon className="max-w-full"/></Box> }
      </Button>
      <Modal
        isCentered={centered}
        isOpen={isOpen}
        onClose={handleClose}
        blockScrollOnMount={false}
        returnFocusOnClose={returnFocusOnClose}
        size={size}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton zIndex="9999" />
          <ModalBody>
            <VideoEmbed
              url={videoUrl}
              autoplay={autoplay}
              showPlayerControls={controls}
              iconColor={iconColor}
              videoStyle="inModal" 
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default VideoModal;
