import clsx from "clsx";
import parse from "html-react-parser";

import { widont } from "@lib/widont";

const HighlightedString = ({ string, color = null, noWidows = true }) => {
  const defaultClass = "text-violet-01";

  let targetString = string;

  if (noWidows) targetString = widont(string);

  return !!targetString && parse(targetString, {
    transform: (node) => {
      if (node.type === "tag" && node.name === "strong") {
        return (
          <span key={node} className={clsx(defaultClass)} style={{ color }}>
            {node.children[0].data}
          </span>
        );
      }
    },
  });
};

export default HighlightedString;
