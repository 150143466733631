import {
  AccordionButton,
  Accordion as AccordionComponent,
  AccordionIcon, AccordionItem, AccordionPanel, Box,
  Heading
} from "@chakra-ui/react";
import { Prose } from "@nikolovlazar/chakra-ui-prose";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player/lazy";

import clsx from "clsx";
import parse from "html-react-parser";

const Accordion = ({ accordionContent }) => {
	const [isSSR, setIsSSR] = useState(true);

	const [isPlaying, setIsPlaying] = useState(false);

	useEffect(() => {
		setIsSSR(false);
	}, []);
	return (
		<AccordionComponent allowMultiple w="100%">
			{accordionContent.map((item, index) => {
				return (
					<AccordionItem key={index}>
						<h2>
							<AccordionButton pl="0">
								<Box flex="1" textAlign="left">
									<Heading size={{ base: "lg", md: "2xl" }}>
										{item.sectionHeading}
									</Heading>
								</Box>
								<AccordionIcon />
							</AccordionButton>
						</h2>
						<AccordionPanel pb={4} pl="0">
							<Prose>{!!item?.sectionContent && parse(item.sectionContent)}</Prose>
							{!!item.videoUrl && !isSSR && (
								<Box
									className={clsx(
										"VideoEmbed__playerWrapper relative justify-center flex"
									)}
									w={"100%"}
									h={"100%"}
								>
									<ReactPlayer
										url={item.videoUrl}
										width={item.videoUrl.includes("wistia") ? "100%" : null}
										height={item.videoUrl.includes("wistia") ? "auto" : null}
										playsinline
										playing={isPlaying}
										controls={true}
										config={{
											wistia: {
												options: {
													controlsVisibleOnLoad: false,
													copyLinkAndThumbnailEnabled: false,
													playButton: true,
													wmode: "transparent",
													fitStrategy: "contain",
													chromeless: false,
													videoFoam: true,
													fullscreenButton: true,
												},
											},
										}}
										className={clsx("VideoEmbed__player", {
											"aspect-w-16 aspect-h-9":
												item.videoUrl.includes("wistia"),
										})}
										onPlay={() => setIsPlaying(true)}
										onPause={() => setIsPlaying(false)}
									/>
								</Box>
							)}
						</AccordionPanel>
					</AccordionItem>
				);
			})}
		</AccordionComponent>
	);
};

export default Accordion;
