import IImage from "@interfaces/Image.interface";
import Image from 'next/image';
import { useEffect, useState } from "react";

import BodyText from "@components/BodyText";
import { Close } from "@components/Icons";
import PlayButton from "@components/PlayButton";
import VideoModal from "@components/VideoModal";
import ButtonBlock from "@sharedComponents/ButtonBlock";
import ReactPlayer from "react-player/lazy";

import { Box, Collapse, Flex, Text } from "@chakra-ui/react";
import clsx from "clsx";

interface IProps {
  autoplay?: boolean;
  backgroundImage?: IImage;
  backgroundImageBlur?: IImage;
  iconColor?: string;
  localVideo?: boolean;
  showPlayerControls?: boolean;
  transcript?: string;
  transcriptBackgroundColor?: string;
  transcriptTitle?: string;
  url: string;
  videoHeight?: number;
  videoStyle?: string;
  videoThumbnail?: any;
  videoWidth?: number;
  onClose?: () => void;
}

const VideoEmbed = ({
  autoplay = false,
  backgroundImage = null,
  backgroundImageBlur = null,
  iconColor = "#6863F2",
  localVideo = false,
  showPlayerControls = true,
  transcript = null,
  transcriptBackgroundColor = "#F6F6F9",
  transcriptTitle = "Transcript",
  url,
  videoHeight = null,
  videoStyle = "inline",
  videoThumbnail = null,
  videoWidth = null,
  onClose = () => null,
}: IProps) => {
  const [isPlaying, setIsPlaying] = useState(
    autoplay || videoStyle === "autoplayInline"
  );
  const [showTranscript, setShowTranscript] = useState(false);

  //console.log(videoStyle)
  //console.log(isPlaying)

  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    setIsSSR(false);
  }, []);

  const handleModalPlayButtonClick = () => {
    setIsVideoOpen(true);
  };

  return (
    <>
      <Box
        className={clsx(
          "VideoEmbed",
          "relative max-w-screen-xl mx-auto w-full"
        )}
        border={
          !!transcript &&
          `1px solid ${transcriptBackgroundColor}`
        }
      >
        {videoStyle === "modal" && (
          <div
            className={clsx(
              "VideoEmbed__modalContainer",
              "relative"
            )}
          >
            <Image
              src={backgroundImage.url}
              blurDataURL={backgroundImageBlur ? backgroundImageBlur.url : null}
              layout="fill"
              objectFit={"cover"}
              alt={backgroundImage.title}
              className={clsx("VideoEmbed__modalBackgroundImage")}
            />
            <PlayButton
              onClick={handleModalPlayButtonClick}
              color={iconColor}
            />
          </div>
        )}
        {videoStyle !== "modal" && (
          <Box
            className={clsx(
              "VideoEmbed__inlineContainer",
              "w-full",
              "relative"
            )}
            sx={{
              aspectRatio:
                videoWidth && videoHeight && `${videoWidth} / ${videoHeight}`
            }}
          >
            {videoThumbnail && (
              <Box w={"100%"}>
                <Image
                  key={videoThumbnail.id}
                  src={videoThumbnail.url}
                  width={videoThumbnail.width}
                  height={videoThumbnail.height}
                  alt={videoThumbnail.title}
                  layout={"intrinsic"}
                  placeholder={
                    videoThumbnail.extension !== "svg" && videoThumbnail.blur
                      ? "blur"
                      : "empty"
                  }
                  blurDataURL={
                    videoThumbnail.blur ? videoThumbnail.blur[0].url : null
                  }
                  className={clsx("VideoEmbed__thumbnail w-full")}
                />
              </Box>
            )}

            {!!url && !isSSR && !localVideo && (
              <Box
                className={clsx(
                  "VideoEmbed__playerWrapper relative justify-center flex"
                )}
                zIndex={videoThumbnail && "20"}
                position={videoThumbnail ? "absolute" : null}
                top={videoThumbnail ? "0" : null}
                left={videoThumbnail ? "0" : null}
                w={"100%"}
                h={"100%"}
              >
                <ReactPlayer
                  url={url}
                  width={url.includes("wistia") ? "100%" : null}
                  height={url.includes("wistia") ? "auto" : null}
                  playsinline
                  playing={isPlaying}
                  controls={showPlayerControls}
                  muted={
                    (autoplay &&
                      videoStyle !== "inModal" &&
                      videoStyle !== "slideDown") ||
                    videoStyle === "autoplayInline"
                  }
                  config={{
                    wistia: {
                      options: {
                        playerColor: iconColor,
                        controlsVisibleOnLoad: false,
                        copyLinkAndThumbnailEnabled: false,
                        // playButton: videoStyle !== "autoplayInline",
                        wmode: "transparent",
                        fitStrategy: "contain",
                        chromeless: false,
                        videoFoam: true,
                        fullscreenButton: true
                      }
                    }
                  }}
                  className={clsx("VideoEmbed__player", {
                    "aspect-w-16 aspect-h-9": url.includes("wistia")
                  })}
                  onPlay={() => setIsPlaying(true)}
                  onPause={() => setIsPlaying(false)}
                />
              </Box>
            )}

            {!!url && localVideo && (
              <video
                muted={autoplay || videoStyle === "autoplayInline"}
                autoPlay={autoplay || videoStyle === "autoplayInline"}
                controls={showPlayerControls}
                playsInline
              >
                <source src={url} type="video/mp4"></source>
              </video>
            )}

            {/* {videoStyle === "inModal" && (
              <button
                type="button"
                className={clsx(
                  "absolute",
                  "top-0",
                  "right-0",
                  "bg-purple-01 text-white-01",
                  "h-12 px-5 font-bold"
                )}
                onClick={onClose}
              >
                X
              </button>
            )} */}
          </Box>
        )}
        {!!transcript && (
          <Box
            backgroundColor={transcriptBackgroundColor}
          >
            <Flex padding="16px 16px 16px 30px" justifyContent="space-between">
              {showTranscript && (
                <Text fontSize="24px" lineHeight="24px">
                  {transcriptTitle}
                </Text>
              )}
              {!showTranscript && (
                <ButtonBlock
                  target={{ text: "Open Transcript" }}
                  eventDescription="Open video transcript"
                  buttonTextColor="black-01"
                  buttonBackgroundColor="transparent"
                  horizontalAlignment="left"
                  onClick={() => setShowTranscript(true)}
                />
              )}
              {showTranscript && (
                <>
                  <ButtonBlock
                    target={{ text: <Close /> }}
                    eventDescription="Close video transcript"
                    buttonTextColor="black-01"
                    buttonBackgroundColor="transparent"
                    horizontalAlignment="right"
                    inline={true}
                    isLandingPage={true} // disables arrow
                    onClick={() => setShowTranscript(false)}
                  />
                </>
              )}
            </Flex>
            <Collapse in={showTranscript} animateOpacity>
              <Box
                padding="24px 14px 50px"
                height="350px"
                overflow="auto"
                margin="0px 16px 16px"
                resize="vertical"
              >
                <BodyText text={transcript} />
              </Box>
            </Collapse>
          </Box>
        )}
      </Box>
      {videoStyle === "modal" && (
        <VideoModal
          deprecatedMode={true}
          videoUrl={url}
          onClose={() => setIsVideoOpen(false)}
          isVideoOpen={isVideoOpen}
        />
      )}
    </>
  );
};

export default VideoEmbed;
